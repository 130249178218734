// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Keywordsearch from "../../blocks/keywordsearch/src/Keywordsearch";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";



const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PtCoursesScreen:{
 component:PtCoursesScreen,
path:"/PtCoursesScreen"},
PtTestsForCourseScreen:{
 component:PtTestsForCourseScreen,
path:"/PtTestsForCourseScreen"},
PtStudentsOnCourseScreen:{
 component:PtStudentsOnCourseScreen,
path:"/PtStudentsOnCourseScreen"},
PtTestScoresForStudentScreen:{
 component:PtTestScoresForStudentScreen,
path:"/PtTestScoresForStudentScreen"},
PtBasicUserCoursesScreen:{
 component:PtBasicUserCoursesScreen,
path:"/PtBasicUserCoursesScreen"},
PtBasicUserAllCoursesScreen:{
 component:PtBasicUserAllCoursesScreen,
path:"/PtBasicUserAllCoursesScreen"},
PtStudentRankingScreen:{
 component:PtStudentRankingScreen,
path:"/PtStudentRankingScreen"},
PtStudentInfoScreen:{
 component:PtStudentInfoScreen,
path:"/PtStudentInfoScreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Keywordsearch:{
 component:Keywordsearch,
path:"/Keywordsearch"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;